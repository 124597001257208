(function ($) {
    $(document).ready(function () {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $('#navigation').addClass('navigation--shrink');
            } else {
                $('#navigation').removeClass('navigation--shrink');
            }
        });
    });
})(jQuery);