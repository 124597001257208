// reCAPTCHA widget
var widget;
var gRecaptchaResponse,
    verifyCallback = function (response) {
        gRecaptchaResponse = response;
    };
var onloadCallback = function () {
    var grecaptchaColor;
    if (window.innerWidth < 992) {
        grecaptchaColor = 'dark';
    } else {
        grecaptchaColor = 'light';
    }
    widget = grecaptcha.render('grecaptcha', {
        'sitekey': '6Le31ykUAAAAALlTP9irXPu6eNgrgwqGKFAWFyca',
        'callback': verifyCallback,
        'theme': grecaptchaColor
    });
};

// Form
(function ($) {

    var field;
    var form = {
        add: function () {
            var html = '';
            field = [{
                id: 'name',
                label: 'Imię i Nazwisko:',
                tag: 'input',
                type: 'text',
                required: true,
                regexp: new RegExp('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]+$')
            }, {
                id: 'email',
                label: 'Adres e-mail:',
                tag: 'input',
                type: 'email',
                required: true,
                regexp: new RegExp('.')
            }, {
                id: 'phone',
                label: 'Telefon:',
                tag: 'input',
                type: 'tel',
                required: true,
                regexp: new RegExp('^[0-9-+() ]+$')
            }, {
                id: 'message',
                label: 'Wiadomość:',
                tag: 'textarea',
                type: '',
                required: true,
                regexp: new RegExp('.')
            }];

            html += '<form action="phpmailer/mail.php" method="POST">';

            for (var i = 0; i < field.length; i++) {
                var fieldId = field[i].id,
                    fieldLabel = field[i].label,
                    fieldType = (field[i].type !== '') ? 'type="' + field[i].type + '"' : '',
                    fieldTag = field[i].tag;

                html += '<div class="form-group">';
                html += '<label class="site-footer__label" for="' + fieldId + '">' + fieldLabel + '</label>';
                html += '<' + fieldTag + ' ' + fieldType + ' name="' + fieldId + '" id="' + fieldId + '" class="form-control site-footer__form-control">' + ((field[i].tag !== 'input') ? '</' + field[i].tag + '>' : '');
                html += '</div>';
            }

            html += '<div id="grecaptcha" class="site-footer__grecaptcha"></div>';
            html += '<div id="notice" class="site-footer__notice"></div>';
            html += '<div class="button-block button-block--xs-center button-block--md-left"><input class="button button--primary" type="submit" value="Wyślij"></div>';
            html += '</form>';

            $('#contact-form').append(html);
        },
        validation: function (formObj, field) {
            var noErrors = true,
                patternStatus;
            $('.site-footer__validation-notice').remove();

            function checkFieldEmpty(id, regexp) {
                patternStatus = regexp.test($('#' + id).val());
                if ($('#' + id).val() === '') {
                    $('#' + id).after('<span class="site-footer__validation-notice">Pole jest wymagane.</span>');

                    if (noErrors === true) {
                        noErrors = patternStatus;
                    }

                } else {
                    checkFieldPattern(id, regexp);
                }
            }

            function checkFieldPattern(id, regexp) {
                patternStatus = regexp.test($('#' + id).val());
                if ($('#' + id).val() !== '' && patternStatus === false) {
                    $('#' + id).after('<span class="site-footer__validation-notice">Wymagana poprawna wartość pola.</span>');

                    if (noErrors === true) {
                        noErrors = patternStatus;
                    }

                }
            }

            for (var i = 0; i < field.length; i++) {
                if (field[i].required === true) {
                    checkFieldEmpty(field[i].id, field[i].regexp);
                } else if (field[i].required === false) {
                    checkFieldPattern(field[i].id, field[i].regexp);
                }
            }

            if (noErrors === true) {
                this.xhr(formObj);
            }
        },
        xhr: function (formObj) {
            $.ajax({
                    url: 'phpmailer/mail.php',
                    type: 'POST',
                    data: formObj.serialize()
                })
                .done(function (response) {
                    form.xhrstatus(response, formObj);
                })
                .fail(function (response) {
                    form.xhrstatus(response);
                });
        },
        xhrstatus: function (msg, formObj) {
            if (msg === 'phpmailer-success') {
                $('#notice').text('Twoja wiadomość została wysłana pomyślnie. Dziękujemy!').addClass('success');
                // Form and reCaptcha reset
                grecaptcha.reset();
                formObj[0].reset();
            } else if (msg === 'phpmailer-error') {
                $('#notice').text('Twoja wiadomość nie została wysłana. Prosimy spróbować później.');
            } else if (msg === 'grecaptcha-false') {
                $('#notice').text('Udowodnij, że nie jesteś robotem!');
            }
        }
    }

    form.add();

    $('body').on('submit', '#contact-form > form', function (event) {
        event.preventDefault();
        form.validation($(this), field);
    });

}(jQuery));