(function ($) {
    $(document).ready(function () {

        var scrollToTop = {
            pos: function () {
                return $(window).scrollTop();
            },
            init: function () {
                $('body').append('<div class="animated-scroll-to-top-button"></div>');
                this.show(this.pos());
            },
            show: function (pos) {
                if (pos >= 200) {
                    $('.animated-scroll-to-top-button').addClass('active');
                } else {
                    $('.animated-scroll-to-top-button').removeClass('active');
                }
            },
            animate: function () {
                $('html, body').animate({
                    scrollTop: 0
                }, 500, function () {
                    $(this).clearQueue();
                });
            }
        }

        scrollToTop.init();

        $(window).on('scroll', function () {
            scrollToTop.show(scrollToTop.pos());
        });

        $('body').on('click', '.animated-scroll-to-top-button', function () {
            scrollToTop.animate();
        });
    });
}(jQuery));