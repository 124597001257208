function initMap() {
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 6,
        center: {
            lat: 51.919438,
            lng: 19.145136
        },
        scrollwheel: false,
        height: 200,
        width: 200
    });

    var infowindow = new google.maps.InfoWindow();
    var service = new google.maps.places.PlacesService(map);

    var shops = [{
        placeName: 'Spy Shop Gdynia',
        placeId: 'ChIJn4rcNCSn_UYRhZDtWUt9JPk'
    }, {
        placeName: 'Spy Shop Szczecin',
        placeId: 'ChIJNxLzsDwJqkcR4dexS66O1tw'
    }, {
        placeName: 'Spy Shop Bydgoszcz',
        placeId: 'ChIJKVuQfccTA0cRPfynVHXqIt0'
    }, {
        placeName: 'Spy Shop Poznań',
        placeId: 'ChIJecWgLy1bBEcRgjV7vPENMIU'
    }, {
        placeName: 'Spy Shop Wrocław',
        placeId: 'ChIJj3NyLYbCD0cRWKsU5SdWRT8'
    }, {
        placeName: 'Spy Shop Katowice',
        placeId: 'ChIJ-Zi8PDjOFkcRJrXF9uBq4gU'
    }, {
        placeName: 'Spy Shop Kraków',
        placeId: 'ChIJR6_PLQRbFkcR4dM38s-HnKo'
    }, {
        placeName: 'Spy Shop Rzeszów',
        placeId: 'ChIJ2yJzfAD7PEcRjITFsUwcLKk'
    }];

    for (var i = 0; i < shops.length; i++) {
        service.getDetails({
            placeId: shops[i].placeId
        }, function (place, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {

                var marker = new google.maps.Marker({
                    map: map,
                    position: place.geometry.location,
                    icon: 'img/google-map-marker.png',
                    title: place.name
                });

                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.setContent('<div class="google-maps-infowindow"><strong>' + place.name + '</strong><br>' +
                        place.formatted_address + '<br>Tel.: <a href="tel:' + place.international_phone_number + '">' + place.international_phone_number + '</a><hr><a href="' + place.url + '">Zobacz w Mapach Google</a></div>');
                    infowindow.open(map, this);
                });

            }
        });
    }
}