(function ($) {
    $(window).on('load', function () {

        var diagram = {
            hotspot: $('[data-toggle="hotspot"]'),

            windowWidth: function () {
                return $(window).width();
            },

            diagramWidth: function () {
                return $('.diagram__caption').outerWidth();
            },

            diagramHeight: function () {
                return $('.diagram__caption').outerHeight();
            },

            caption: function (clickEl) {
                if (this.windowWidth() < 1200) {
                    this.hideCaption(clickEl);
                    this.showCaption(clickEl);
                }
            },

            showCaption: function (clickEl) {
                clickEl.parent().toggleClass('active');
                this.captionTextPos(clickEl);
            },

            hideCaption: function (clickEl) {
                this.hotspot.not(clickEl).next().removeAttr('style').parent().removeClass('active');
            },

            captionTextPos: function (clickEl) {
                var positionLeft = clickEl.parent().position().left;
                var positionTop = clickEl.parent().position().top;

                if (positionLeft < (this.diagramWidth() / 2)) {
                    clickEl.next().css({
                        left: 0,
                        width: (this.diagramWidth() / 1.8)
                    });
                } else {
                    clickEl.next().css({
                        right: 0,
                        width: (this.diagramWidth() / 1.8)
                    });
                }

                if (positionTop < (this.diagramHeight() / 2)) {
                    clickEl.next().css({
                        top: 40,
                    });
                    this.animateToTop(clickEl);
                } else {
                    clickEl.next().css({
                        bottom: 40,
                    });
                    this.animateToTop(clickEl.next());
                }
            },

            animateToTop: function (clickEl) {
                $('html, body').animate({
                    scrollTop: clickEl.offset().top - 120
                }, 500, function () {
                    $(this).clearQueue();
                });
            }
        }

        $(diagram.hotspot).on('click', function () {
            diagram.caption($(this));
        });

        $(window).on('resize', function () {
            diagram.hideCaption();
        });

    });
}(jQuery));