(function ($) {
    $(window).on('load', function () {
        var cachedWindowWidth = $(window).width(),
            slider = {
                animateStart: null,
                animateInterval: 5000,

                windowHeight: function () {
                    return $(window).height();
                },

                setHeight: function () {
                    $('#slider .slider__inner').css({
                        height: this.windowHeight()
                    });

                    slider.captionCenter();
                },

                captionCenter: function () {
                    $('#slider .slider__caption').css({
                        bottom: 'auto',
                        height: 'auto',
                        top: 'auto'
                    });

                    $('#slider .slider__caption').each(function () {
                        var $height = $(this).outerHeight();
                        $(this).css({
                            bottom: 0,
                            height: $height,
                            top: 0
                        });
                    });
                },

                interval: function () {

                    var $lastItemIndex = $('.slider__inner > .slider__item').length - 1,
                        $changeToItemIndex,
                        pattern = /slider__item--active/;

                    $('.slider__inner > .slider__item').each(function (index) {
                        var $elClassName = $(this).attr('class');

                        if (pattern.test($elClassName) === true) {
                            if (index === $lastItemIndex) {
                                $changeToItemIndex = 0;
                            } else {
                                $changeToItemIndex = index + 1;
                            }
                        }
                    });

                    slider.changeItem($('.slider__indicator:eq(' + $changeToItemIndex + ')'));
                },

                changeItem: function (el) {
                    var pattern = /slider__indicator--active/,
                        $elClassName = el.attr('class'),
                        $elIndex = el.index();

                    if (pattern.test($elClassName) !== true) {
                        el.parent().find('.slider__indicator').removeClass('slider__indicator--active');
                        el.addClass('slider__indicator--active');

                        $('.slider__inner > .slider__item').removeClass('slider__item--active');
                        $('.slider__inner > .slider__item:eq(' + $elIndex + ')').addClass('slider__item--active');
                    }
                },

                animate: function () {
                    this.animateStart = setInterval(function () {
                        slider.interval();
                    }, this.animateInterval);
                },

                init: function () {

                    this.setHeight();

                    if ($('#slider .slider__item').length > 1) {
                        this.animate();
                    }
                }
            }

        slider.init();

        $(window).on('resize', function () {
            var newWidth = $(window).width();
            if (newWidth !== cachedWindowWidth) {
                slider.setHeight();
                cachedWindowWidth = newWidth;
            }
        });

        $('#slider .slider__indicators').hover(function () {
            clearInterval(slider.animateStart);
        }, function () {
            slider.animate();
        });

        $('#slider .slider__indicator').on('click', function () {
            slider.changeItem($(this));
        });
    });
})(jQuery);