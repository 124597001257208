(function ($) {
    $(document).ready(function () {
        var el = $('#applications .applications__caption');

        function autoHeight(width) {
            el.removeAttr('style');
            if (width >= 768) {
                var height = 0;
                el.each(function () {
                    if ($(this).outerHeight() > height) {
                        height = $(this).outerHeight();

                    }
                });
                el.css({
                    height: height
                });
            }
        }

        autoHeight($(window).width());

        $(window).on('resize', function () {
            autoHeight($(window).width());
        });

    });
})(jQuery);