(function ($) {
    $(document).ready(function () {

        function addToArray() {
            dataLayer.push({
                'event': 'odkrycie-mapy'
            });
        }

        $('#btn-show-map').on('click', function () {
            addToArray();
        });
    });
}(jQuery));