(function ($) {
    $(document).ready(function () {

        var menu = {
            checkTouchScreen: function () {
                if ('ontouchstart' in document.documentElement !== true) {
                    $('#navigation').addClass('desktop');
                }
            },
            calcMenuHeight: function () {
                var height = 0;
                $('#navigation .main-menu, #navigation .sub-menu').each(function () {
                    if ($(this).outerHeight() > height) {
                        height = $(this).outerHeight();
                    }
                });
                $('#navigation .main-menu, #navigation .sub-menu').css('height', height);
            },
            toggleMainMenu: function (el) {
                this.calcMenuHeight();
                el.toggleClass('active');
                $('#navigation .main-menu').toggleClass('active');
            },
            checkSubMenu: function () {
                $('#navigation .drop-down').each(function () {
                    var $subMenuName = $(this).text();
                    $(this).after('<span class="sub-menu-toggle-next"></span>');
                    $(this).parent().find('> .sub-menu').prepend('<li class="heading"><span class="sub-menu-toggle-back"></span><span class="sub-menu-name">' + $subMenuName + '</span></li>');
                });

            },
            toggleSubMenu: function (el) {
                if (el.attr('class') === 'sub-menu-toggle-back') {
                    el.parent().parent().removeClass('active');
                } else if (el.attr('class') === 'sub-menu-toggle-next') {
                    el.next().addClass('active');
                }
                $('html, body').animate({
                    scrollTop: 0
                }, 300);
            }
        }

        menu.checkTouchScreen();

        menu.checkSubMenu();

        $('body').on('click', '#navigation .navigation__hamburger-menu', function () {
            menu.toggleMainMenu($(this));
        });

        $('body').on('click', '#navigation .sub-menu-toggle-next', function () {
            menu.toggleSubMenu($(this));
        });

        $('body').on('click', '#navigation .sub-menu-toggle-back', function () {
            menu.toggleSubMenu($(this));
        });

        $(window).on('resize', function () {
            var $windowWidth = $(window).width();
            if ($windowWidth >= 768) {
                $('#navigation .active').removeClass('active');
            }
        });
    });
}(jQuery));