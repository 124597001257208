(function ($) {
    $(window).on('load', function () {
        var metalDetectors = {
            caption: $('.gallery__item--animate .gallery__caption'),
            getWindowWidth: function () {
                return $(window).width();
            },
            init: function () {
                var windowWidth = this.getWindowWidth();
                if (windowWidth >= 768) {
                    this.setCaptionPosition();
                } else {
                    this.caption.removeAttr('style');
                }
            },
            setCaptionPosition: function () {
                this.caption.each(function () {
                    var captionPosition = $(this).find('p').outerHeight() - 10;
                    $(this).css('bottom', -captionPosition);
                });
            }
        }

        metalDetectors.init();

        $(window).on('resize', function () {
            metalDetectors.init();
        });
    });
}(jQuery));