(function ($) {
    $(document).ready(function () {
        var mapToggle = {
            addHideMapButton: function () {
                $('#footer-contact-form').prepend('<button id="btn-hide-map" class="button button--primary site-footer__btn-hide-map"><i class="fa fa-angle-left"></i></button>');
            },
            hideContactForm: function () {
                var $windowWidth = $(window).width();
                var $contactFormWidth = $('#footer-contact-form').outerWidth();
                var $translation = (($windowWidth - $contactFormWidth) / 2) + $contactFormWidth;

                $('#footer-contact-form').css({
                    '-webkit-transform': 'translate(' + $translation + 'px, 0)',
                    'transform': 'translate(' + $translation + 'px, 0)'
                });

                $('#btn-hide-map').addClass('active');
            },
            showContactForm: function () {
                $('#footer-contact-form').css({
                    '-webkit-transform': 'translate(0, 0)',
                    'transform': 'translate(0, 0)'
                });
                $('#btn-hide-map').removeClass('active');
            }
        }

        mapToggle.addHideMapButton();

        $('#btn-show-map').on('click', function () {
            mapToggle.hideContactForm();
        });
        $('#btn-hide-map').on('click', function () {
            mapToggle.showContactForm();
        });

        $(window).on('resize', function () {
            mapToggle.showContactForm();
        });
    });
}(jQuery));